<template>
  <billing-banner :badge-text="badgeText" :help-text="helpText" icon="timer-line" :title="title" :type="bannerType">
    <template #icon>
      <cx-icon name="timer-line" size="2xl" />
    </template>
    <template #buttons="{ ctaButtonColor }">
      <cx-button :color="ctaButtonColor" to="/settings/plan-details/upgrade">{{ $t('buttons.upgradeNow') }}</cx-button>
      <cx-button v-if="shouldShowDismiss" color="quaternary" @click="$emit('dismiss')">{{
        $t('buttons.dismiss')
      }}</cx-button>
    </template>
  </billing-banner>
</template>

<script>
import { CxButton, CxIcon } from '~/components/cx';
import { DANGER, PRIMARY, WARN } from '~/components/cx/CxButton/CxButton.vue';
import { subscription } from '~/support/utils';

import BillingBanner from '../BillingBanner';
import { BILLING_BANNER_TYPES } from '../BillingBanner/BillingBanner.vue';

export const DAYS_LEFT_TO_WARN = 5;
export const DATA_PRODUCTS_ROUTE = 'data-products';
export const DATA_PRODUCT_DETAILS_ROUTE = 'data-products-id-details-summary';

export default {
  name: 'TrialBanner',

  components: {
    BillingBanner,
    CxButton,
    CxIcon,
  },

  props: {
    expires: {
      required: true,
      type: [Number, String],
    },
  },

  computed: {
    badgeText() {
      return this.hasExpired ? '' : this.$tc('daysRemaining', this.daysLeft, { count: this.daysLeft });
    },

    bannerType() {
      switch (true) {
        case this.hasExpired:
          return BILLING_BANNER_TYPES.danger;
        case this.shouldWarn:
          return BILLING_BANNER_TYPES.warn;
        default:
          return BILLING_BANNER_TYPES.info;
      }
    },

    daysLeft() {
      return subscription.daysLeft(this.expires);
    },

    hasExpired() {
      return this.daysLeft === 0;
    },

    helpText() {
      if (this.hasExpired) return this.$t('helpText.expired');

      switch (this.$route.name) {
        case DATA_PRODUCTS_ROUTE:
        case DATA_PRODUCT_DETAILS_ROUTE:
          return this.$t('helpText.dataProducts');
        default:
          return this.$t('helpText.default');
      }
    },

    shouldShowDismiss() {
      return !this.shouldWarn;
    },

    shouldWarn() {
      return this.daysLeft <= DAYS_LEFT_TO_WARN;
    },

    title() {
      const date = this.$formatting.localizedDate(this.expires);

      if (this.hasExpired) return this.$t('title.expired', { date });

      switch (this.$route.name) {
        case DATA_PRODUCTS_ROUTE:
        case DATA_PRODUCT_DETAILS_ROUTE:
          return this.$t('title.dataProducts');
        default:
          return this.$t('title.expiring', { date });
      }
    },

    upgradeButtonColor() {
      switch (true) {
        case this.hasExpired:
          return DANGER;
        case this.shouldWarn:
          return WARN;
        default:
          return PRIMARY;
      }
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "daysRemaining": "{count} day remaining | {count} days remaining",
    "helpText": {
      "dataProducts": "Upgrade to Pro to use 25 active data products or upgrade to Enterprise for unlimited active data products.",
      "default": "Upgrade to Pro or Enterprise to access all of Crux platform benefits.",
      "expired": "Any active data products have been deactivated. Upgrade to Pro or Enterprise to access all of Crux platform benefits."
    },
    "title": {
      "dataProducts": "Your Pro Trial only allows for one active data product",
      "expired": "Your Pro Trial expired on {date}",
      "expiring": "Your Pro Trial expires on {date}"
    }
  }
}
</i18n>
