<template>
  <billing-banner :help-text="helpText" icon="timer-line" :title="title" :type="bannerType">
    <template #icon>
      <cx-icon name="timer-line" size="2xl" />
    </template>
    <template #buttons="{ ctaButtonColor }">
      <cx-button :color="ctaButtonColor" to="/settings/plan-details/upgrade">{{ $t('buttons.upgradeNow') }}</cx-button>
    </template>
  </billing-banner>
</template>

<script>
import { CxButton, CxIcon } from '~/components/cx';
import { SUBSCRIPTION_PLANS } from '~/support/constants';

import BillingBanner from '../BillingBanner';
import { BILLING_BANNER_TYPES } from '../BillingBanner/BillingBanner.vue';

export const DATA_PRODUCTS_ROUTE = 'data-products';
export const DATA_PRODUCT_DETAILS_ROUTE = 'data-products-id-details-summary';

export default {
  name: 'ExpiredBanner',

  components: {
    BillingBanner,
    CxButton,
    CxIcon,
  },

  props: {
    expires: {
      required: true,
      type: [Number, String],
    },
  },

  computed: {
    bannerType() {
      return BILLING_BANNER_TYPES.danger;
    },

    helpText() {
      return this.$t('helpText');
    },

    title() {
      const date = this.$formatting.localizedDate(this.expires);

      switch (this.$auth.user.subscription?.plan) {
        case SUBSCRIPTION_PLANS.pro:
          return this.$t('title.pro', { date });
        case SUBSCRIPTION_PLANS.enterprise:
          return this.$t('title.enterprise', { date });
        case SUBSCRIPTION_PLANS.cruxForgeAnnual:
          return this.$t('title.cruxForgeAnnual', { date });
        default:
          return '';
      }
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "helpText": "Any active data products have been deactivated. Renew Pro or upgrade to Enterprise now to access all of Crux platform benefits.",
    "title": {
      "enterprise": "Your Enterprise plan expired on {date}",
      "cruxForgeAnnual": "Your Crux Forge Annual plan expired on {date}",
      "pro": "Your Pro plan expired on {date}"
    }
  }
}
</i18n>
