<template>
  <cx-fade-transition>
    <span v-hubble="'cx-chirp'" class="bg-gray-700 px-4 py-3 rounded shadow-lg text-white"><slot /></span>
  </cx-fade-transition>
</template>

<script>
import { CxFadeTransition } from '../CxFadeTransition';

export default {
  name: 'CxChirp',

  components: { CxFadeTransition },
};
</script>
