<template>
  <billing-banner :help-text="$t('helpText')" :title="$t('title')">
    <template #icon>
      <cx-icon name="grid-line" size="2xl" />
    </template>
    <template #buttons="{ ctaButtonColor }">
      <cx-button :color="ctaButtonColor" to="/settings/plan-details/upgrade">
        {{ $t('buttons.upgradeNow') }}
      </cx-button>
      <cx-button color="quaternary" @click="$emit('dismiss')">
        {{ $t('buttons.dismiss') }}
      </cx-button>
    </template>
  </billing-banner>
</template>

<script>
import { CxButton, CxIcon } from '~/components/cx';

import BillingBanner from '../BillingBanner';

export default {
  name: 'FreeBanner',
  components: {
    BillingBanner,
    CxButton,
    CxIcon,
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "helpText": "With a Free plan, you can build data products but activating them requires upgrading to Pro or Enterprise to access all Crux platform benefits.",
    "title": "You are on a Free plan"
  }
}
</i18n>
