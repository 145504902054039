<template>
  <aside class="flex h-full px-4">
    <div class="flex flex-col">
      <app-sidebar-logo :is-sidebar-expanded="isSidebarOpen" @toggle-expand="onToggleExpand" />

      <nav class="flex-1 flex-col inline-flex items-center" :aria-label="$t('ariaLabels.navigation.main')">
        <app-sidebar-links />

        <app-sidebar-utilities @toggle-expand="onToggleExpand" />
      </nav>
    </div>

    <app-sidebar-content v-if="isSidebarOpen && $auth.user" />
  </aside>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import AppSidebarContent from './subcomponents/AppSidebarContent';
import AppSidebarLinks from './subcomponents/AppSidebarLinks';
import AppSidebarLogo from './subcomponents/AppSidebarLogo';
import AppSidebarUtilities from './subcomponents/AppSidebarUtilities';

export default {
  name: 'AppSidebar',

  hubble: 'app-sidebar',

  components: {
    AppSidebarLogo,
    AppSidebarContent,
    AppSidebarUtilities,
    AppSidebarLinks,
  },

  computed: {
    ...mapState('settings', ['isSidebarOpen']),
  },

  watch: {
    // Close sidebar on route change
    '$route.name'() {
      this.setSidebarStatus(false);
    },
  },

  methods: {
    ...mapActions('settings', ['setSidebarStatus']),

    onToggleExpand() {
      this.setSidebarStatus(!this.isSidebarOpen);
    },
  },
};
</script>
