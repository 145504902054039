<template>
  <ul class="flex flex-col space-y-1">
    <!-- This link is styled differently. Rather than updating the AppSidebarItem
    component to accommodate a one-off, I thought it
    would be easier to do this. -->
    <li
      v-if="canViewStudio"
      v-tooltip="{ content: $t('menuItems.studio'), placement: 'right', distance: 30 }"
      class="bg-blue-600 hover:bg-blue-700 rounded-lg text-white"
    >
      <nuxt-link
        class="flex focus:outline-none focus:ring-blue-600 h-10 items-center justify-center ring-2 ring-transparent rounded-lg transition-shadow w-10"
        :aria-label="$t('menuItems.studio')"
        :to="routes.studio"
      >
        <cx-icon :name="icons.studio" size="2xl" />
      </nuxt-link>
    </li>
    <app-sidebar-item
      v-if="canViewProducts"
      v-hubble="'data-products'"
      :to="routes.dataProducts"
      :label="$t('pages.dataProducts.title')"
      :is-active="isActiveRoute(routes.dataProducts.name)"
    >
      <cx-icon :name="getIconName(routes.dataProducts.name, icons.dataProducts)" size="2xl" />
    </app-sidebar-item>
    <app-sidebar-item
      v-if="canViewHealthDashboard"
      v-hubble="'health-dashboard'"
      :to="routes.healthDashboard"
      :label="$t('menuItems.healthDashboard')"
      :is-active="isActiveRoute(routes.healthDashboard.name)"
      @click="trackHealthDashboardClick"
    >
      <cx-icon :name="getIconName(routes.healthDashboard.name, icons.healthDashboard)" size="2xl" />
    </app-sidebar-item>
    <app-sidebar-item
      v-if="canViewConnectionSettings"
      v-hubble="'connections'"
      :to="routes.connectionSettings"
      :label="$t('menuItems.connections')"
      :is-active="isActiveRoute(routes.connectionSettings.name)"
    >
      <cx-icon :name="getIconName(routes.connectionSettings.name, icons.connectionSettings)" size="2xl" />
    </app-sidebar-item>
    <app-sidebar-item
      v-if="canViewCatalog"
      v-hubble="'catalog'"
      :to="routes.catalog"
      :label="$t('menuItems.catalog')"
      :is-active="isActiveRoute(routes.catalog.name)"
      @click="trackCatalogClick"
    >
      <cx-icon :name="getIconName(routes.catalog.name, icons.catalog)" size="2xl" />
    </app-sidebar-item>
    <app-sidebar-item
      v-if="canViewOrgs"
      v-hubble="'organizations'"
      :to="routes.organizations"
      :label="$t('menuItems.organizations')"
      :is-active="isActiveRoute(routes.organizations.name)"
    >
      <cx-icon :name="getIconName(routes.organizations.name, icons.organizations)" size="2xl" />
    </app-sidebar-item>
  </ul>
</template>

<script>
import { CxIcon } from '~/components/cx';
import { ENTITLEMENTS, NAV_ICONS, NAV_ROUTES } from '~/support/constants';
import { navigation } from '~/support/utils';

import AppSidebarItem from '../AppSidebarItem';

export default {
  name: 'AppSidebarLinks',

  hubble: 'app-sidebar-links',

  components: {
    AppSidebarItem,
    CxIcon,
  },

  computed: {
    canViewCatalog() {
      return this.$hasEntitlement(ENTITLEMENTS.catalog.view);
    },

    canViewConnectionSettings() {
      return this.$hasEntitlement(ENTITLEMENTS.connection.view);
    },

    canViewHealthDashboard() {
      return this.$hasEntitlement(ENTITLEMENTS.healthDashboard.view) && this.$flag('showHealthDashboardV2');
    },

    canViewOrgs() {
      return this.$hasEntitlement(ENTITLEMENTS.org.view);
    },

    canViewProducts() {
      return this.$hasEntitlement(ENTITLEMENTS.dataProduct.view);
    },

    canViewStudio() {
      return this.$hasEntitlement(ENTITLEMENTS.studio.view) && this.$flag('showCruxStudio');
    },

    icons() {
      return NAV_ICONS;
    },

    routes() {
      return NAV_ROUTES;
    },
  },

  methods: {
    getIconName(route, icon) {
      return navigation.getIconName(route, icon, this.$route);
    },

    isActiveRoute(name) {
      return this.$route.name?.startsWith(name);
    },

    trackCatalogClick() {
      this.$mixpanel.track('Crux Catalog Viewed');
    },

    trackHealthDashboardClick() {
      this.$mixpanel.track('Health Dashboard Viewed');
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "menuItems": {
      "catalog": "Crux Catalog",
      "connections": "Connections",
      "healthDashboard": "Health Dashboard",
      "organizations": "Organizations",
      "studio": "Crux Studio"
    }
  }
}
</i18n>
