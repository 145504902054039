<template>
  <div class="border border-gray-200 container flex h-11 items-center justify-between p-3 rounded-t-lg w-88">
    <div class="astro-text-heading-sm">{{ $t('notifications') }}</div>
    <div class="flex items-center space-x-2">
      <button v-if="hasNotifications" class="hover:underline sticky text-blue-600 text-xs" @click.stop="$emit('clear')">
        {{ $t('clearAll') }}
      </button>
      <cx-badge :color="badgeColor">{{ notificationCount }}</cx-badge>
    </div>
  </div>
</template>

<script>
import { CxBadge } from '~/components/cx';

export default {
  name: 'NotificationsHeader',

  components: {
    CxBadge,
  },

  props: {
    notificationCount: {
      default: 0,
      type: Number,
    },
  },

  computed: {
    badgeColor() {
      return this.hasNotifications ? 'darkerBlue' : 'darkestGray';
    },

    hasNotifications() {
      return this.notificationCount > 0;
    },
  },
};
</script>

<style scoped>
.container {
  /*  this color isn't in flowbite*/
  background: #f4f4f5;
}
</style>

<i18n lang="json">
{
  "en": {
    "clearAll": "Clear all",
    "notifications": "Notifications"
  }
}
</i18n>
