<template>
  <div
    :id="id"
    class="flex"
    :class="{ 'bg-gray-50': notification.isRead }"
    @click.stop="handleClick"
    @mouseover="handleMouseover"
  >
    <div class="p-3">
      <div class="flex h-6 items-center justify-center rounded-full w-6" :class="iconBackgroundColor">
        <cx-icon :name="iconName" :class="iconColor" size="lg" />
      </div>
    </div>
    <div class="py-3 w-68">
      <cx-tooltip :content="notification.title" :delay-show="tooltipDelayShow">
        <div class="flex-1 font-bold pb-1 text-gray-800 text-sm truncate">{{ notification.title }}</div>
      </cx-tooltip>
      <div class="flex items-center pb-3 space-x-2">
        <cx-badge :color="badgeColor">{{ notification.category }}</cx-badge>
        <div class="flex items-center space-x-1">
          <cx-icon class="text-gray-400" name="calendar-line" size="xs" />
          <span class="font-normal text-gray-400 text-xs">{{ $formatting.tableDateTime(notification.timestamp) }}</span>
        </div>
      </div>
      <div class="font-light pb-2 text-gray-600 text-xs">{{ notification.details }}</div>
      <nuxt-link
        :aria-describedby="id"
        class="cursor-pointer hover:underline text-blue-600 text-xs"
        :to="detailsLink"
        target="_blank"
      >
        {{ $t('viewDetails') }}
      </nuxt-link>
    </div>
    <div class="p-3">
      <div v-if="isUnread" class="bg-blue-600 h-2 rounded-full w-2" />
    </div>
  </div>
</template>

<script>
import { CxBadge, CxIcon, CxTooltip } from '~/components/cx';
import { NOTIFICATIONS_DELAY } from '~/support/constants';

export const INFO = 'info';
export const SUCCESS = 'success';
export const WARNING = 'warning';
export const ERROR = 'error';

export const BADGE_COLOR_MAP = {
  [INFO]: 'blue',
  [SUCCESS]: 'green',
  [WARNING]: 'yellow',
  [ERROR]: 'red',
};

export const ICON_BACKGROUND_COLOR_MAP = {
  [INFO]: 'bg-blue-100',
  [SUCCESS]: 'bg-green-100',
  [WARNING]: 'bg-yellow-100',
  [ERROR]: 'bg-red-100',
};

export const ICON_COLOR_MAP = {
  [INFO]: 'text-blue-600',
  [SUCCESS]: 'text-green-600',
  [WARNING]: 'text-yellow-600',
  [ERROR]: 'text-red-600',
};

export const ICON_NAME_MAP = {
  [INFO]: 'information-fill',
  [SUCCESS]: 'checkbox-circle-fill',
  [WARNING]: 'alert-fill',
  [ERROR]: 'error-warning-fill',
};

export default {
  name: 'NotificationsCenterNotification',

  components: {
    CxBadge,
    CxIcon,
    CxTooltip,
  },

  props: {
    isLast: {
      default: false,
      type: Boolean,
    },

    notification: {
      default: () => {},
      type: Object,
    },
  },

  computed: {
    badgeColor() {
      return BADGE_COLOR_MAP[this.notification.severity];
    },

    detailsLink() {
      return `/datasets/${this.notification.datasetId}/health`;
    },

    iconBackgroundColor() {
      return ICON_BACKGROUND_COLOR_MAP[this.notification.severity];
    },

    iconColor() {
      return ICON_COLOR_MAP[this.notification.severity];
    },

    iconName() {
      return ICON_NAME_MAP[this.notification.severity];
    },

    id() {
      return `notifications-center-notification-${this._uid}`;
    },

    isUnread() {
      return !this.notification.isRead;
    },

    tooltipDelayShow() {
      return NOTIFICATIONS_DELAY;
    },
  },

  methods: {
    handleClick() {
      this.$emit('clear', this.notification.id);
    },

    handleMouseover() {
      if (this.notification.isRead) return;

      this.$emit('read', this.notification.id);
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "viewDetails": "View Details"
  }
}
</i18n>
