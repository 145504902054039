<template>
  <ul class="flex flex-col items-center mt-auto relative space-y-1">
    <notifications-center v-if="$flag('showNotificationsCenter')" />

    <app-sidebar-item
      v-hubble="'settings'"
      :label="$t('settings')"
      name="settings"
      :to="routes.settings"
      :is-active="isActiveRoute(routes.settings.name)"
    >
      <cx-icon :name="getIconName(routes.settings.name, icons.settings)" size="2xl" />
    </app-sidebar-item>

    <app-sidebar-item
      v-hubble="'support-chat'"
      :label="$t('supportChat')"
      name="forum"
      class="relative"
      @click="onChatClick"
    >
      <cx-icon name="question-line" size="2xl" />
      <span
        v-if="intercomCount > 0"
        class="absolute bg-red-500 h-4 min-w-4 right-0 rounded-full text-white text-xs top-1"
      >
        {{ intercomCount }}
      </span>
    </app-sidebar-item>

    <li class="!mt-4 cursor-pointer">
      <cx-avatar
        v-hubble="'account-avatar'"
        class="focus:outline-none focus:ring-blue-600 ring-2 ring-transparent transition-shadow"
        :aria-label="$t('accountDetails')"
        :name="$auth.user.name || ''"
        tag="button"
        @click="onAvatarClick"
      />
    </li>
  </ul>
</template>

<script>
import { CxAvatar, CxIcon } from '~/components/cx';
import { navigation } from '~/support/utils';

import AppSidebarItem from '../AppSidebarItem';
import NotificationsCenter from '../NotificationsCenter';

export const ICONS = { settings: 'settings-3' };
export const ROUTES = { settings: { name: 'settings' } };

export default {
  name: 'AppSidebarUtilities',

  hubble: 'app-sidebar-utilities',

  components: {
    CxAvatar,
    CxIcon,
    NotificationsCenter,
    AppSidebarItem,
  },

  data() {
    return {
      intercomCount: 0,
    };
  },

  computed: {
    icons() {
      return ICONS;
    },

    routes() {
      return ROUTES;
    },
  },

  mounted() {
    /* c8 ignore start */
    window.Intercom('onUnreadCountChange', (unreadCount) => {
      this.intercomCount = unreadCount;
    });
    /* c8 ignore stop */
  },

  methods: {
    getIconName(route, icon) {
      return navigation.getIconName(route, icon, this.$route);
    },

    isActiveRoute(name) {
      return this.$route.name?.startsWith(name);
    },

    onAvatarClick() {
      this.$emit('toggle-expand');
    },

    onChatClick() {
      window.Intercom('show');
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "accountDetails": "Account Details",
    "notifications": "Notifications",
    "settings": "Settings",
    "supportChat": "Support Chat"
  }
}
</i18n>
