import { isEmpty } from 'lodash-es';

const DELAY_BETWEEN_CHECK = 60 * 60 * 1000;
const EVENTS = ['mousemove', 'mousedown', 'resize', 'keydown', 'wheel'];
const REDIRECT_PAGE = 'login';

export const AutoLogout = {
  beforeUnmount() {
    this.removeEventListeners();
  },

  data() {
    const isUserLogin = !isEmpty(this.$store.state.auth.user);

    return {
      expireTime: null,
      isUserLogin,
    };
  },

  methods: {
    checkForInactivity() {
      if (this.expireTime < Date.now()) {
        this.isUserLogin = !this.isUserLogin;
      }
    },

    removeEventListeners() {
      EVENTS.forEach((event) => {
        window.removeEventListener(event, this.updateExpireTime);
      });
    },

    setEventListeners() {
      EVENTS.forEach((event) => {
        window.addEventListener(event, this.updateExpireTime);
      });
    },

    updateExpireTime() {
      this.expireTime = Date.now() + DELAY_BETWEEN_CHECK;
    },
  },

  mounted() {
    this.updateExpireTime();
    this.setEventListeners();

    const interval = setInterval(async () => {
      this.checkForInactivity();

      if (!this.isUserLogin) {
        clearInterval(interval);

        await this.$auth.logout();
        await this.$router.push({ name: REDIRECT_PAGE });
      }
    }, DELAY_BETWEEN_CHECK);
  },
};
