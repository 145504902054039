<template>
  <div class="border border-gray-600 flex flex-col mt-auto p-4 rounded-lg">
    <div class="flex">
      <div class="bg-gray-200 flex h-12 items-center justify-center rounded-full shrink-0 w-12">
        <cx-icon :name="userRoleIcon" class="text-gray-600" size="2xl" />
      </div>

      <div class="flex flex-col justify-center ml-2 truncate w-full">
        <div class="flex justify-between w-full">
          <p v-hubble="'user-name'" class="font-semibold mr-4 text-white truncate">{{ $auth.user.name }}</p>
          <cx-badge
            v-hubble="'user-role-badge'"
            color="gray"
            class="flex h-[22px] items-center justify-center ml-auto"
            >{{ userRoleName }}</cx-badge
          >
        </div>

        <p v-hubble="'user-email'" class="text-gray-300 text-xs truncate">{{ $auth.user.email }}</p>
      </div>
    </div>

    <subscription-wrapper v-slot="{ daysLeftText, hasEndDate }" :subscription="subscription">
      <div class="flex items-center mt-4">
        <!-- TODO: replace this with cx-button when there's a fitting theme -->
        <button
          v-hubble="'manage-profile-button'"
          class="border border-white flex font-medium grow items-center justify-center mr-2 py-2 rounded-lg text-white text-xs"
          @click="$router.push({ name: 'settings-general' })"
        >
          {{ $t('manageSettings') }}
        </button>

        <cx-tooltip :content="freePlanTooltip || daysLeftText" :disabled="!hasEndDate">
          <nuxt-link
            v-hubble="'days-left-icon'"
            to="/settings/plan-details/your-plan"
            class="flex h-10 items-center justify-center w-10"
            :aria-label="$t('viewYourPlan')"
          >
            <cx-icon name="timer-line" class="text-gray-400" size="2xl" />
          </nuxt-link>
        </cx-tooltip>

        <cx-tooltip :content="$t('logout')">
          <nuxt-link
            v-hubble="'logout-button'"
            class="flex h-10 items-center justify-center w-10"
            :to="{ name: 'logout' }"
          >
            <cx-icon name="logout-box-r-line" class="text-gray-400" size="2xl" />
          </nuxt-link>
        </cx-tooltip>
      </div>
    </subscription-wrapper>
  </div>
</template>

<script>
import { CxBadge, CxIcon, CxTooltip } from '~/components/cx';
import SubscriptionWrapper from '~/components/subscription/SubscriptionWrapper';
import {
  USER_ROLE_MAP,
  CRUX_ADMIN,
  DATA_ENGINEER,
  DATA_PRODUCT_EDITOR,
  DATA_PRODUCT_VIEWER,
  ORGANIZATION_ADMIN,
  PROSPECT,
  SUBSCRIPTION_PLANS,
} from '~/support/constants';

export const ROLE_ICON_MAP = {
  [CRUX_ADMIN]: 'shield-user-line',
  [ORGANIZATION_ADMIN]: 'user-settings-line',
  [DATA_ENGINEER]: 'code-line',
  [DATA_PRODUCT_EDITOR]: 'pencil-line',
  [DATA_PRODUCT_VIEWER]: 'eye-line',
  [PROSPECT]: 'search-eye-line',
};

export default {
  name: 'AppSidebarRoleCard',

  hubble: 'app-sidebar-role-card',

  components: { CxBadge, CxIcon, CxTooltip, SubscriptionWrapper },

  computed: {
    freePlanTooltip() {
      return this.subscription.plan === SUBSCRIPTION_PLANS.free ? this.$t('freeTooltip') : '';
    },

    subscription() {
      return this.$auth.user?.subscription;
    },

    userRole() {
      return this.$auth.user.roles[0];
    },

    userRoleIcon() {
      return ROLE_ICON_MAP[this.userRole];
    },

    userRoleName() {
      return USER_ROLE_MAP[this.userRole];
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "freeTooltip": "Free Plan",
    "viewYourPlan": "View your subscription plan",
    "manageSettings": "Manage Settings",
    "logout": "Logout"
  }
}
</i18n>
