<template>
  <div class="bottom-8 fixed flex justify-center left-1/2 z-notifications">
    <cx-chirp v-if="chirpNotification" class="-translate-x-1/2 transform">
      <div class="flex space-x-1">
        <cx-icon v-if="chirpIcon" :name="chirpIcon" size="2xl" />
        <span>{{ chirpNotification }}</span>
      </div>
    </cx-chirp>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { CxChirp, CxIcon } from '~/components/cx';

export default {
  name: 'ChirpNotification',

  components: {
    CxChirp,
    CxIcon,
  },

  computed: {
    ...mapState('notifications', ['chirpIcon', 'chirpNotification']),
  },
};
</script>
