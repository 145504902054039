<template>
  <div class="border border-gray-200 container flex h-11 items-center justify-center p-3 rounded-b-lg w-88">
    <div class="flex items-center space-x-2">
      <cx-badge color="darkerBlue">+{{ notificationCount }}</cx-badge>
      <button class="hover:underline sticky text-blue-600 text-xs" @click="$emit('view')">
        {{ $t('viewAll') }}
      </button>
    </div>
  </div>
</template>

<script>
import { CxBadge } from '~/components/cx';

export default {
  name: 'NotificationsFooter',

  components: {
    CxBadge,
  },

  props: {
    notificationCount: {
      default: 0,
      type: Number,
    },
  },
};
</script>

<style scoped>
.container {
  /*  this color isn't in flowbite*/
  background: #f4f4f5;
}
</style>

<i18n lang="json">
{
  "en": {
    "viewAll": "View all"
  }
}
</i18n>
