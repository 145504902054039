<template>
  <div class="flex flex-col h-full justify-between pl-4 space-y-4 w-[23rem]">
    <app-sidebar-subscription-card />

    <app-sidebar-role-card />
  </div>
</template>

<script>
import AppSidebarRoleCard from './subcomponents/AppSidebarRoleCard';
import AppSidebarSubscriptionCard from './subcomponents/AppSidebarSubscriptionCard';

export default {
  name: 'AppSidebarContent',

  components: { AppSidebarRoleCard, AppSidebarSubscriptionCard },
};
</script>
