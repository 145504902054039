<template>
  <div class="bg-gray-700 flex h-full pr-4 py-4">
    <app-sidebar v-if="$auth.user" />

    <div class="flex flex-1 flex-col h-full overflow-hidden space-y-4">
      <main class="bg-gray-50 flex flex-1 flex-col h-full overflow-hidden relative rounded-lg">
        <subscription-banners v-if="shouldShowSubscriptionBanner" class="flex-none" />
        <portal-target name="banner" />
        <slot class="flex-1" />

        <portal-target class="flex-none" name="tray" />

        <portal-target name="bottomSideBar" />
      </main>
      <portal-target name="relatedContent" />
    </div>

    <aside class="flex h-full">
      <portal-target name="rightSideBar" />
    </aside>

    <toast-notifications />
    <chirp-notification />
  </div>
</template>

<script>
import { PortalTarget } from 'portal-vue';

import AppSidebar from '~/components/common/AppSidebar';
import ChirpNotification from '~/components/notifications/ChirpNotification';
import ToastNotifications from '~/components/notifications/ToastNotifications';
import SubscriptionBanners from '~/components/subscription/SubscriptionBanners';
import { AutoLogout } from '~/mixins/auto-logout';
import { ENTITLEMENTS } from '~/support/constants';

export default {
  name: 'App',

  components: {
    AppSidebar,
    ChirpNotification,
    PortalTarget,
    SubscriptionBanners,
    ToastNotifications,
  },

  mixins: [AutoLogout],

  computed: {
    shouldShowSubscriptionBanner() {
      return this.$hasEntitlement(ENTITLEMENTS.plan.view);
    },
  },

  /* c8 ignore start */
  created() {
    useHead({
      meta: [{ name: 'build-number', content: this.$config.public.buildNumber }],
      titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - Crux` : 'Welcome - Crux';
      },
    });
  },
  /* c8 ignore stop */

  /* c8 ignore start */
  mounted() {
    this.$mixpanel.register({
      Organization: this.$auth.user.orgId,
      'Plan Type': this.$auth.user.subscription?.plan,
    });

    this.$mixpanel.identify(this.$auth.user.email);

    this.$mixpanel.people.set({
      'User Role': this.$auth.user.roles ? this.$auth.user.roles.join(', ') : '',
      'Is Cruxer': ['cruxinformatics.com', 'cruxdata.com'].includes(this.$auth.user.email.replace(/.*@/, '')),
    });

    window.Intercom('boot', {
      app_id: this.$config.public.intercomAppId,
      email: this.$auth.user.email,
      hide_default_launcher: true,
      name: this.$auth.user.name,
    });
  },
  /* c8 ignore stop */
};
</script>

<style lang="postcss">
html,
body,
#__nuxt,
#__layout {
  @apply h-full overflow-hidden;
}
</style>
