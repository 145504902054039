<template>
  <li class="rounded-lg" :class="containerClass">
    <cx-tooltip :content="label" placement="right" :distance="30">
      <component
        :is="computedTag"
        class="flex focus:outline-none focus:ring-blue-600 h-10 items-center justify-center ring-2 ring-transparent rounded-lg transition-shadow w-10"
        :aria-label="ariaLabel"
        :to="to"
      >
        <slot />
      </component>
    </cx-tooltip>
  </li>
</template>

<script>
import { CxTooltip } from '~/components/cx';

export default {
  name: 'AppSidebarItem',

  components: {
    CxTooltip,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: '',
    },

    to: {
      default: () => {},
      type: [Object, String],
    },
  },

  computed: {
    ariaLabel() {
      return !this.disabled ? this.label : '';
    },

    computedTag() {
      return this.disabled ? 'span' : this.to ? defineNuxtLink({}) : 'button';
    },

    containerClass() {
      const cursorClass = this.disabled ? 'cursor-not-allowed' : 'cursor-pointer';

      if (this.disabled) return `text-gray-500 ${cursorClass}`;

      const activeClass = this.isActive ? 'text-white bg-gray-600' : 'text-gray-400 hover:text-white';

      return `${activeClass} ${cursorClass}`;
    },
  },
};
</script>
