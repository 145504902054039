<template>
  <div class="divide-solid divide-y notifications">
    <notifications-center-notification
      v-for="(notification, index) in trimmedNotifications"
      :key="index"
      :notification="notification"
      @clear="$emit('clear', $event)"
      @read="$emit('read', $event)"
    />
  </div>
</template>

<script>
import { NOTIFICATIONS_CENTER_VIEW_LIMIT } from '~/support/constants';

import { NotificationsCenterNotification } from './subcomponents/NotificationsCenterNotification';

export default {
  name: 'NotificationsCenterNotifications',

  components: {
    NotificationsCenterNotification,
  },

  props: {
    notifications: {
      default: () => [],
      type: Array,
    },
  },

  computed: {
    trimmedNotifications() {
      if (this.notifications.length <= NOTIFICATIONS_CENTER_VIEW_LIMIT) return this.notifications;

      return this.notifications.slice(0, NOTIFICATIONS_CENTER_VIEW_LIMIT);
    },
  },
};
</script>
