<template>
  <div class="bg-white border-gray-200 border-t-0 flex flex-col h-36 items-center justify-center rounded-b-lg w-full">
    <!-- party popper emoji -->
    <div class="text-2xl">&#127881;</div>
    <div class="font-normal text-gray-400 text-sm">{{ $t('upToDate') }}</div>
  </div>
</template>

<script>
export default {
  name: 'EmptyState',
};
</script>

<i18n lang="json">
{
  "en": {
    "upToDate": "You're up to date!"
  }
}
</i18n>
