<template>
  <free-banner v-if="shouldShowFreeBanner" @dismiss="dismissFreeBanner" />
  <trial-banner v-else-if="shouldShowTrialBanner" :expires="trialExpiry" @dismiss="dismissTrialBanner" />
  <expired-banner v-else-if="shouldShowExpiredSubscriptionBanner" :expires="trialExpiry" />
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { BE_INACTIVE_STATUS, SUBSCRIPTION_PLANS } from '~/support/constants';
import { subscription } from '~/support/utils';

import ExpiredBanner from '../ExpiredBanner';
import FreeBanner from '../FreeBanner';
import TrialBanner from '../TrialBanner';
import { DAYS_LEFT_TO_WARN } from '../TrialBanner/TrialBanner.vue';

export default {
  name: 'SubscriptionBanners',

  components: {
    FreeBanner,
    TrialBanner,
    ExpiredBanner,
  },

  computed: {
    inFree() {
      return this.subscriptionPlan === SUBSCRIPTION_PLANS.free;
    },

    inTrial() {
      return this.subscriptionPlan === SUBSCRIPTION_PLANS.trial;
    },

    shouldAlwaysShowTrialBanner() {
      return this.trialDaysLeft <= DAYS_LEFT_TO_WARN;
    },

    shouldShowExpiredSubscriptionBanner() {
      return this.$auth.user.subscription?.status === BE_INACTIVE_STATUS;
    },

    shouldShowFreeBanner() {
      return this.inFree && !this.freeBannerIsDismissed;
    },

    shouldShowTrialBanner() {
      return this.inTrial && (!this.trialBannerIsDismissed || this.shouldAlwaysShowTrialBanner);
    },

    subscriptionPlan() {
      return this.$auth.user.subscription?.plan;
    },

    trialDaysLeft() {
      const { endDate } = this.$auth.user.subscription;

      return endDate ? subscription.daysLeft(endDate) : 0;
    },

    trialExpiry() {
      return this.$auth.user.subscription.endDate;
    },

    ...mapState('settings', ['freeBannerIsDismissed', 'trialBannerIsDismissed']),
  },

  methods: {
    ...mapActions('settings', ['dismissFreeBanner', 'dismissTrialBanner']),
  },
};
</script>
