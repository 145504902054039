<template>
  <app-sidebar-item
    v-click-outside="() => (open = false)"
    :label="$t('notifications')"
    name="notifications"
    icon-name="notifications"
    @click="toggleOpen"
  >
    <span v-if="hasNotifications" class="absolute bg-red-600 h-2 left-6 rounded-full text-white text-xs top-2 w-2" />
    <div
      v-show="open"
      ref="menu"
      class="bg-white list-none max-h-[500px] overflow-auto rounded-lg shadow text-base w-88 z-50"
    >
      <div ref="arrow" class="arrow" />
      <notifications-header :notification-count="notifications.length" @clear="handleClearAll" />
      <div v-if="loadingNotifications" class="bg-gray-50 flex h-45 items-center justify-center w-full">
        <cx-spinner size="sm" />
      </div>
      <empty-state v-else-if="!hasNotifications" />
      <notifications-center-notifications
        v-else
        :notifications="notifications"
        @clear="handleClear"
        @read="handleRead"
      />
      <notifications-footer
        v-if="shouldShowFooter"
        :notification-count="notificationOverflowCount"
        @view="$emit('view')"
      />
    </div>
  </app-sidebar-item>
</template>

<script>
import { createPopper } from '@popperjs/core';
import vClickOutside from 'click-outside-vue3';

import { CxSpinner } from '~/components/cx';
import NotificationsCenterNotifications from '~/components/notifications/NotificationsCenterNotifications';
import { NOTIFICATIONS_CENTER_VIEW_LIMIT, NOTIFICATIONS_DELAY } from '~/support/constants';
import { MOCK_NOTIFICATIONS_LONG } from '~/support/fixtures';

import EmptyState from './subcomponents/EmptyState';
import NotificationsFooter from './subcomponents/NotificationsFooter';
import NotificationsHeader from './subcomponents/NotificationsHeader';
import AppSidebarItem from '../AppSidebarItem';

export default {
  name: 'NotificationsCenter',

  directives: {
    clickOutside: vClickOutside.directive,
  },

  components: {
    AppSidebarItem,
    CxSpinner,
    NotificationsHeader,
    NotificationsFooter,
    NotificationsCenterNotifications,
    EmptyState,
  },

  data() {
    return {
      loadingNotifications: false,
      notifications: [],
      open: false,
    };
  },

  computed: {
    hasNotifications() {
      return this.notifications.length > 0;
    },

    notificationOverflowCount() {
      return this.notifications.length - NOTIFICATIONS_CENTER_VIEW_LIMIT;
    },

    shouldShowFooter() {
      return this.notifications.length > NOTIFICATIONS_CENTER_VIEW_LIMIT;
    },
  },

  watch: {
    open(newVal) {
      if (!newVal) return;

      this.$nextTick(() => {
        this.createPopper();
      });
    },
  },

  beforeMount() {
    this.fetchNotifications();
  },

  methods: {
    createPopper() {
      this.popper = createPopper(this.$el, this.$refs.menu, {
        placement: 'top-start',
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: this.$refs.arrow,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [45, 0],
            },
          },
        ],
      });

      this.popper.forceUpdate();
    },

    fetchNotifications() {
      this.loadingNotifications = true;
      setTimeout(() => {
        this.notifications = MOCK_NOTIFICATIONS_LONG;
        this.loadingNotifications = false;
      }, NOTIFICATIONS_DELAY);
    },

    handleClear(event) {
      const readIndex = this.notifications.findIndex((item) => item.id === event);

      this.notifications.splice(readIndex, 1);
    },

    handleClearAll() {
      this.notifications = [];
    },

    handleRead(event) {
      const readIndex = this.notifications.findIndex((item) => item.id === event);

      this.notifications[readIndex].isRead = true;
    },

    toggleOpen() {
      this.open = !this.open;
    },
  },
};
</script>

<style scoped>
/*popper arrow styles*/
.arrow,
.arrow:before {
  @apply absolute border-gray-200 border-l border-t h-5 -top-1.5 w-5;
  /*  color not in flowbite*/
  background: #f4f4f5;
}

.arrow {
  @apply invisible;
}

.arrow:before {
  @apply rotate-45 visible;
  content: '';
}
</style>

<i18n lang="json">
{
  "en": {
    "notifications": "Notifications"
  }
}
</i18n>
