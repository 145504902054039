<template>
  <svg
    :aria-label="$t('ariaLabels.images.cruxLogo')"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="8" fill="white" />
    <path d="M12 34.5531L20.9528 23.9846L12.0243 13.4468H18.1304L27.0589 23.9846L18.1001 34.5531H12Z" fill="#D00000" />
    <path d="M27.8151 23.0843L27.812 23.0879H27.8181L27.8151 23.0843Z" fill="#C81E1E" />
    <path d="M27.8151 23.0843L35.9819 13.4528H29.8818L24.765 19.4893L27.8151 23.0843Z" fill="#111928" />
    <path d="M27.812 24.8872L24.759 28.4858L29.9 34.553H36.0001L27.812 24.8872Z" fill="#111928" />
  </svg>
</template>

<script>
export const PRIMARY = 'black';
export const FILL_CLASS_MAP = {
  black: 'text-black',
  white: 'text-white',
};

export default {
  name: 'AppLogo',
};
</script>
